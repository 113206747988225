/* eslint-disable no-shadow */
/* eslint-disable import/extensions */
/* eslint-disable brace-style */
/* eslint-disable eqeqeq */
import React, { useState, useRef } from 'react';
// main editor
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
// PLUGINS//
// Emoticons
import 'froala-editor/js/plugins/emoticons.min.js';
import 'froala-editor/css/plugins/emoticons.min.css';
// Colors
import 'froala-editor/js/plugins/colors.min.js';
import 'froala-editor/css/plugins/colors.min.css';
// quote
import 'froala-editor/js/plugins/quote.min.js';
// lists
import 'froala-editor/js/plugins/lists.min.js';
// align
import 'froala-editor/js/plugins/align.min.js';
// insert file
import 'froala-editor/js/plugins/file.min.js';
import 'froala-editor/css/plugins/file.min.css';
// insert image
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/css/plugins/image.min.css';
// image TUI
import 'froala-editor/js/third_party/image_tui.min.js';
import 'froala-editor/css/third_party/image_tui.min.css';
// draggable image/file
import 'froala-editor/js/plugins/draggable.min.js';
import 'froala-editor/css/plugins/draggable.min.css';
// insert video
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/css/plugins/video.min.css';
// link
import 'froala-editor/js/plugins/link.min.js';
// url
import 'froala-editor/js/plugins/url.min.js';
// files manager
import 'froala-editor/js/plugins/files_manager.min.js';
import 'froala-editor/css/plugins/files_manager.min.css';
// font size & font style
import 'froala-editor/js/plugins/font_family.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
// special characters
import 'froala-editor/js/plugins/special_characters.min.js';
import 'froala-editor/css/plugins/special_characters.min.css';
// table
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/css/plugins/table.min.css';
// line breaker
import 'froala-editor/js/plugins/line_breaker.min.js';
import 'froala-editor/css/plugins/line_breaker.min.css';
// Tribute mention plugin
import Tribute from 'tributejs';
import 'tributejs/dist/tribute.css';

import FroalaEditorComponent from 'react-froala-wysiwyg';
import PropTypes from 'prop-types';

import styles from './EditorFull.module.css';

const EditorFull = ({
  customConfig, model, onModelChange, initialContent,
  triggerLists, type, handleAction, companyId, handleTyping,
}) => {
  const [editor, setEditor] = useState();
  const ref = useRef({ editor: null });

  const tribute = new Tribute({
    collection: [
      {
        lookup: 'fullName',
        fillAttr: 'fullName',
        values: triggerLists ? [
          ...triggerLists,
        ] : [],
        selectTemplate(item) {
          let photoSource;
          if (item.original?.photoUrl) {
            if (item.original?.photoUrl?.charAt(0) === '/') {
              photoSource = process.env.REACT_APP_PRIMARY_API_URL + item.original?.photoUrl;
            } else {
              photoSource = item.original?.photoUrl;
            }
          }
          return `<span id="mentioned-user" contenteditable="false" data-mentioned-user-id="${item.original._id}" class="fr-deletable fr-tribute" style="padding:1px; background-color:#e8ffff; border-radius:2px; display:inline-flex; align-items:center"><img style="width:20px; height:20px; object-fit: cover; margin-right:3px; border-radius:100%;" src="${photoSource}" /><a href="/companies/${companyId}/profiles/${item.original._id}">${item.original?.fullName}</a></span>`;
        },
        menuItemTemplate(item) {
          let photoSource;
          if (item.original?.photoUrl) {
            if (item.original?.photoUrl?.charAt(0) === '/') {
              photoSource = process.env.REACT_APP_PRIMARY_API_URL + item.original?.photoUrl;
            } else {
              photoSource = item.original?.photoUrl;
            }
          }
          return `<span style="display:inline-flex; width:250px; align-items:center"><img style="width:20px; height:20px; object-fit: cover; margin-right:3px; border-radius:100%;" src="${photoSource}" /><a style="font-weight:400;">${item.original?.fullName}</a></span>`;
        },
      },
    ],

  });

  const config = {
    // general options
    key: process.env.REACT_APP_EDITOR_LICENSE_KEY,
    linkAlwaysBlank: true,
    autofocus: true,
    placeholderText: 'Tulis catatanmu disini...',
    toolbarStickyOffset: 55,
    attribution: false,
    typingTimer: 250,
    // toolbar buttons
    toolbarButtons: {
      moreRich: {
        buttons: ['emoticons', 'insertFiles', 'insertImage', 'insertLink', 'insertVideo', 'insertFile', 'insertTable', 'fontAwesome', 'specialCharacters', 'embedly', 'insertHR'],
        align: 'left',
        buttonsVisible: 3,
      },
      moreText: {
        buttons: ['bold', 'italic', 'strikeThrough', 'backgroundColor', 'fontSize', 'underline', 'textColor', 'subscript', 'superscript', 'fontFamily', 'inlineClass', 'inlineStyle', 'clearFormatting'],
        align: 'left',
        buttonsVisible: 5,
      },
      moreParagraph: {
        buttons: ['formatOLSimple', 'formatUL', 'alignLeft', 'alignCenter', 'alignRight', 'alignJustify', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
        align: 'left',
        buttonsVisible: 2,
      },
      moreMisc: {
        buttons: ['undo', 'redo'],
        align: 'right',
        buttonsVisible: 2,
      },
    },
    // For uploading
    requestWithCredentials: true,
    requestHeaders: {
      Authorization: `jwt ${localStorage.getItem('token')}`,
    },
    imageUploadMethod: 'POST',
    videoUploadMethod: 'POST',
    fileUploadMethod: 'POST',
    filesManagerUploadMethod: 'POST',
    imageUploadURL: `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/editor-uploads/files/${companyId}`,
    videoUploadURL: `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/editor-uploads/files/${companyId}`,
    fileUploadURL: `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/editor-uploads/files/${companyId}`,
    filesManagerUploadURL: `${process.env.REACT_APP_PRIMARY_API_URL}/api/v1/editor-uploads/files/${companyId}`,
    events: {

      async initialized() {
        await new Promise((resolve) => setTimeout(resolve, 1));
        const editor = this;
        setEditor(editor);
        tribute.attach(editor.el);
        editor.el.focus();
        editor.events.on(
          'keydown',
          // eslint-disable-next-line consistent-return
          (e) => {
            if (e.which === 13 && tribute.isActive) {
              return false;
            }
            if (type === 'comment') {
              if (e.ctrlKey && e.which === 13) {
                setTimeout(handleAction, 250);
                return false;
              }
            }
            if (handleTyping) handleTyping(e.key);
          },
          true,
        );

        if (initialContent) editor.html.set(initialContent);
      },
      'image.beforeUpload': function (images) {
        // Return false if you want to stop the image upload.
      },
      'image.uploaded': function (response) {
        // Image was uploaded to the server.
      },
      'image.inserted': function ($img, response) {
        // Image was inserted in the editor.
      },
      'image.replaced': function ($img, response) {
        // Image was replaced in the editor.
      },
      'image.error': function (error, response) {
        // Bad link.
        if (error.code == 1) { console.log(error); }

        // No link in upload response.
        else if (error.code == 2) { console.log(error); }

        // Error during image upload.
        else if (error.code == 3) { console.log(error); }

        // Parsing response failed.
        else if (error.code == 4) { console.log(error); }

        // Image too text-large.
        else if (error.code == 5) { console.log(error); }

        // Invalid image type.
        else if (error.code == 6) { console.log(error); }

        // Image can be uploaded only to same domain in IE 8 and IE 9.
        else if (error.code == 7) { console.log(error); }

        // Response contains the original server response to the request if available.
      },
      'video.beforeUpload': function (videos) {
        // Return false if you want to stop the video upload.
      },
      'video.uploaded': function (response) {
        // Image was uploaded to the server.
      },
      'video.inserted': function ($vid, response) {
        // Image was inserted in the editor.
      },
      'video.replaced': function ($vid, response) {
        // Image was replaced in the editor.
      },
      'video.error': function (error, response) {
        // Bad link.
        if (error.code == 1) { console.log(error); }

        // No link in upload response.
        else if (error.code == 2) { console.log(error); }

        // Error during video upload.
        else if (error.code == 3) { console.log(error); }

        // Parsing response failed.
        else if (error.code == 4) { console.log(error); }

        // Image too text-large.
        else if (error.code == 5) { console.log(error); }

        // Invalid video type.
        else if (error.code == 6) { console.log(error); }

        // Image can be uploaded only to same domain in IE 8 and IE 9.
        else if (error.code == 7) { console.log(error); }

        // Response contains the original server response to the request if available.
      },
      'file.beforeUpload': function (files) {
        // Return false if you want to stop the file upload.
      },
      'file.uploaded': function (response) {
        // Image was uploaded to the server.
      },
      'file.inserted': function ($file, response) {
        // Image was inserted in the editor.
      },
      'file.replaced': function ($file, response) {
        // Image was replaced in the editor.
      },
      'file.error': function (error, response) {
        // Bad link.
        if (error.code == 1) { console.log(error); }

        // No link in upload response.
        else if (error.code == 2) { console.log(error); }

        // Error during file upload.
        else if (error.code == 3) { console.log(error); }

        // Parsing response failed.
        else if (error.code == 4) { console.log(error); }

        // Image too text-large.
        else if (error.code == 5) { console.log(error); }

        // Invalid file type.
        else if (error.code == 6) { console.log(error); }

        // Image can be uploaded only to same domain in IE 8 and IE 9.
        else if (error.code == 7) { console.log(error); }

        // Response contains the original server response to the request if available.
      },
    },

  };

  return (
    <FroalaEditorComponent
      ref={ref}
      tag="textarea"
      model={model}
      onModelChange={onModelChange}
      config={{ ...config, ...customConfig }}
    />
  );
};

EditorFull.propTypes = {
  customConfig: PropTypes.object.isRequired,
  model: PropTypes.string.isRequired,
  onModelChange: PropTypes.func.isRequired,
  initialContent: PropTypes.string.isRequired,
  triggerLists: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  handleAction: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  handleTyping: PropTypes.func,
};

EditorFull.defaultProps = {
  handleTyping: undefined,
};

export default EditorFull;
