import React, {
  useState, useContext, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import GroupsOutlinedIcon from '@mui/icons-material/GroupsOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import { useSnackbar } from 'notistack';
import { useMediaQuery } from '@mui/material';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import Title from '../../components/Title/Title';
import FetchTeamsCards from './FetchTeamsCards/FetchTeamsCards';
import CreateTeamButton from './CreateTeamButton/CreateTeamButton';
import styles from './DashboardPage.module.css';
import { actionTypes } from '../../reducers/reducer';
import { GlobalContext } from '../../contexts/GlobalStateProvider';
import handleStatusMsg from '../../utilities/handleStatusMsg';
import handleLoadings from '../../utilities/handleLoadings';
import PageLoading from '../../components/UI/Button/PageLoading/PageLoading';
import HomeSubNavHeader from './HomeSubNavHeader/HomeSubNavHeader';
import { BillingActions, CompanyActions, TeamActions } from '../../actions';
import ModalSelectCompany from './ModalSelectCompany/ModalSelectCompany';
import ModalWelcomeUser from './ModalWelcomeUser/ModalWelcomeUser';
import { getStoreDashboardWidget, getStoreWelcomeUserPopUp, setStoreDashboardWidget } from '../../utilities/localStorage';
import { getSessionWelcomeUserPopUp, setSessionWelcomeUserPopUp } from '../../utilities/sessionStorage';
import { CompanyConstants, PageConstants } from '../../constants';
import EmptyTeamsSectionForAdmins from './EmptyTeamsSectionForAdmins/EmptyTeamsSectionForAdmins';
import EmptyTeamsSectionForUsers from './EmptyTeamsSectionForUsers/EmptyTeamsSectionForUsers';
import { useInitiateRoleUser } from '../../hooks/RoleHooks';
import { hasPermission } from '../../actions/RoleActions';
import { roles } from '../../constants/RoleConstants';
import StickySubscriptionBar from '../BillingPage/StickySubscriptionBar/StickySubscriptionBar';
import { useTrackMetricVisitor } from '../../hooks/WebTrackingHooks';
import ContainerFilterBar from '../../components/Container/FilterBar/FilterBar';
import ArchivedItemsBar from '../../components/Container/ArchivedItemsBar/ArchivedItemsBar';
import { useArchivedTeamsHooks } from '../../hooks/TeamHooks';
import GeneralSubNavBar from '../../components/GeneralSubNavBar/GeneralSubNavBar';
import { generateRoutesAndTitle } from '../../actions/BreadCrumbActions';
import WidgetSection from './WidgetSection/WidgetSection';
import { useSearch } from '../../hooks/HelperHooks';
import { InputSearchGeneral } from '../../components/UI_V2/Input/Search';
import SortButton from '../../components/SortButton/SortButton';
import { setSortedTeams } from '../../actions/TeamActions';

const containerWithSidebarStyles = {
  true: `${styles.container} ${styles.sidebarOpen}`,
  false: styles.container,
};

function DashboardPage() {
  const [{
    user, currentCompany, currentCompanies,
    teams, currentRoleUser,
    isSidebarOpen,
    sortedTeams,
  }, dispatch] = useContext(GlobalContext);
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const [loadings, setLoadings] = useState([]);
  const isMobile = useMediaQuery('(max-width:720px)');
  const [showArchivedItems, setShowArchivedItems] = useState(false);
  const [showSelectCompany, setShowSelectCompany] = useState();
  const [showWelcomePopUp, setShowWelcomePopUp] = useState();
  const [showWelcomeTutorial, setShowWelcomeTutorial] = useState();
  const [initiateListTeams, loadMoreListTeams, unarchiveTeam] = useArchivedTeamsHooks();
  const [showWelcomeProfileForm, setShowWelcomeProfileForm] = useState();
  const [isDontShowWelcomeAgain, setIsDontShowWelcomeAgain] = useState(false);

  const initialWidgetList = getStoreDashboardWidget({
    userId: user?._id,
    companyId: params?.companyId,
  });

  const [widgetList, setWidgetList] = useState(initialWidgetList || [
    'widgetTeam',
    'widgetMyTaskAndRecentVisit',
  ]);

  const { companyId } = params;

  useInitiateRoleUser({});
  useTrackMetricVisitor({
    companyId,
    user,
  });

  const isUserAdminOrAbove = hasPermission(currentRoleUser, roles.adminOrAbove);
  const isUserManagerOrAbove = hasPermission(currentRoleUser, roles.managerOrAbove);

  const goToWelcomePage = () => {
    history.push('/welcome');
  };

  useEffect(() => {
    if (user._id === undefined) {
      history.push(`/check-login?previousPath=${location.pathname}`);
      return;
    }

    if (currentCompanies.length < 1) {
      goToWelcomePage();
      return;
    }

    const startLoadings = handleLoadings('dashboardPage', [...loadings], 'start');
    setLoadings([...startLoadings]);
    const fetchCompanyApiAndSetTeams = async () => {
      try {
        const resultCompany = await CompanyActions.getAndSaveCompanyAndLastOpenedCompany(
          { companyId, companies: currentCompanies, userId: user?._id }, dispatch,
        );
        if (resultCompany) {
          TeamActions.initiateTeams(
            {
              currentCompany: resultCompany,
              userId: user?._id,
            },
            dispatch,
          );
        }
      } catch (err) {
        const status = handleStatusMsg(err, 'error');
        dispatch({
          type: actionTypes.SET_ERROR_RESPONSE,
          errorResponse: { message: status.message },
        });
        history.push(`/errors?previousPath=${location.pathname}`);
      } finally {
        const endLoadings = handleLoadings('dashboardPage', [...loadings], 'end');
        setLoadings([...endLoadings]);
      }
    };
    fetchCompanyApiAndSetTeams();
  }, [location]);

  useEffect(() => {
    if (currentCompany?._id) {
      setShowSelectCompany(false);
    } else {
      setShowSelectCompany(true);
    }

    const valueStore = getStoreWelcomeUserPopUp({
      userId: user?._id,
      companyId: currentCompany?._id,
    });

    if (valueStore) {
      let valueSession = getSessionWelcomeUserPopUp({
        userId: user?._id,
        companyId: currentCompany?._id,
      });

      if (!valueSession) {
        valueSession = {
          ...valueStore,
          isOpen: true,
        };

        setSessionWelcomeUserPopUp({
          userId: user?._id,
          companyId: currentCompany?._id,
          value: valueSession,
        });
      }

      if (valueSession && valueSession?.isOpen) {
        setShowWelcomeTutorial(valueStore?.welcomeTutorial);
        // setShowWelcomeTrialMessage(valueStore?.welcomeTrialMessage);
      } else {
        setShowWelcomeTutorial(false);
        // setShowWelcomeTrialMessage(false);
      }
    }

    const isUserMandatoryFieldExistForWelcomeFillingProfile = user?.age
    && user?.location
    && user?.country
    && user?.teamSizeCategory
    && user?.gender
    && user?.appUsedBeforeCicle
    && user?.reasonUsingCicle
    && user?.whereKnowCicle
    && user?.companyRemoteOrOfflineStatus;

    setShowWelcomeProfileForm(!isUserMandatoryFieldExistForWelcomeFillingProfile);
  }, [currentCompany]);

  useEffect(() => {
    if (showWelcomeTutorial || showWelcomeProfileForm) {
      setShowWelcomePopUp(true);
    } else {
      setShowWelcomePopUp(false);
    }
  }, [showWelcomeTutorial, showWelcomeProfileForm]);

  const handleClickDontShowWelcomeAgain = (value) => {
    setIsDontShowWelcomeAgain(value);
  };

  const handleCloseWelcomePopUp = () => {
    const valueStore = getStoreWelcomeUserPopUp({
      userId: user?._id,
      companyId: currentCompany?._id,
    });

    setShowWelcomePopUp(false);

    if (valueStore) {
      setSessionWelcomeUserPopUp({
        userId: user?._id,
        companyId: currentCompany?._id,
        value: {
          ...valueStore,
          isOpen: false,
        },
      });
    }

    if (isDontShowWelcomeAgain) {
      CompanyActions.modifyShowWelcomeTutorialAndTrialMessage({
        userId: user?._id,
        companyId: currentCompany?._id,
        typeAction: CompanyConstants.typeCallback.DELETE,
      });
    }

    // make sure all value trigger reset again
    handleClickDontShowWelcomeAgain(false);
    setShowWelcomeProfileForm(false);
    setShowWelcomeTutorial(false);
  };

  const isAllTeamsEmpty = teams.length < 1;
  const listHqs = teams.filter((team) => team.type === 'hq');
  const isHqsEmpty = listHqs.length < 1;
  const listTeams = teams.filter((team) => team.type === 'team');
  const isTeamsEmpty = listTeams.length < 1;
  const listProjects = teams.filter((team) => team.type === 'project');
  const isProjectsEmpty = listProjects.length < 1;

  const handleShowArchivedItems = () => {
    setShowArchivedItems(true);
  };

  const handleHideArchivedItems = () => {
    setShowArchivedItems(false);
  };

  const handleChangeSortedTeams = (newList) => {
    setSortedTeams({ sortedTeams: newList }, dispatch);
  };

  const onDragEnd = (result) => {
    const {
      destination, source, draggableId,
    } = result;
    if (destination === null) {
      return;
    }

    if (destination.index === source.index && destination.droppableId === source.droppableId) {
      return;
    }

    const removedDraggableId = widgetList.filter((id) => id !== draggableId);

    const newList = removedDraggableId.toSpliced(destination.index, 0, draggableId);

    setWidgetList(newList);
    setStoreDashboardWidget({
      userId: user?._id,
      companyId: params?.companyId,
      dashboardWidget: newList,
    });
  };

  const {
    searchResult,
    keyword,
    keywordArray,
    setKeyword,
    isTyping,
    onChange,
    resetSearch,
  } = useSearch({ originalList: sortedTeams });

  const pageType = PageConstants.pageType.home;
  const breadcrumb = generateRoutesAndTitle({ type: pageType, data: { currentCompany } });

  return (
    <>
      <Title title="Beranda" />
      <GeneralSubNavBar
        breadcrumbRoutes={breadcrumb.routes}
        pageTitle={breadcrumb.title}
        withMiddleNavBar={false}
        withBottomNavBar={false}
      />
      {currentCompany?._id && (
        <HomeSubNavHeader
          handleShowArchivedItems={handleShowArchivedItems}
          company={currentCompany}
        />
      )}
      {currentCompany?._id && <StickySubscriptionBar />}
      <PageLoading wait="dashboardPage" loadings={loadings}>
        <div className={containerWithSidebarStyles[isSidebarOpen]}>
          {showArchivedItems
            && (
            <ArchivedItemsBar
              initiateListDataFunc={initiateListTeams}
              loadMoreListDataFunc={loadMoreListTeams}
              unarchiveDataFunc={unarchiveTeam}
              onClose={handleHideArchivedItems}
              barTitle="Arsip Tim"
            />
            )}
          {currentCompany?._id && isAllTeamsEmpty
          && isUserManagerOrAbove && <EmptyTeamsSectionForAdmins params={params} />}
          {currentCompany?._id && isAllTeamsEmpty
          && !isUserManagerOrAbove && <EmptyTeamsSectionForUsers />}
          {currentCompany?._id && !isAllTeamsEmpty && (
            <>
              <DragDropContext
                onDragEnd={onDragEnd}
              >
                <Droppable key="widget-list" droppableId="widget-list" direction="vertical">
                  {(providedDroppable) => (
                    <div
                      className={styles.list}
                      ref={providedDroppable.innerRef}
                      {...providedDroppable.droppableProps}
                    >
                      {widgetList.map((widgetListId, index) => (
                        <Draggable key={widgetListId} draggableId={widgetListId} index={index}>
                          {(provided) => (
                            <>
                              {widgetListId === 'widgetTeam' && (
                              <div
                                className={styles.teamSection}
                                ref={provided.innerRef}
                                {...provided?.draggableProps}
                                {...provided?.dragHandleProps}
                              >
                                {!isAllTeamsEmpty && (
                                <div className={styles.searchAndSortSection}>
                                  <div className={styles.search}>
                                    <InputSearchGeneral
                                      onChange={onChange}
                                      value={keyword}
                                      placeholder="Cari tim..."
                                      onClickCloseIcon={resetSearch}
                                      size="lg"
                                      autoFocus={false}
                                    />
                                  </div>
                                  <div className={styles.sort}>
                                    <SortButton
                                      originalList={teams}
                                      handleChangeOriginalListToNewList={handleChangeSortedTeams}
                                      companyId={companyId}
                                      userId={user?._id}
                                    />
                                  </div>
                                </div>
                                )}
                                {!isHqsEmpty && (
                                <>
                                  <div
                                    className={`${styles.DashboardPage__header} ${styles.DashboardPage__relative}`}
                                  >
                                    <div
                                      className={`${styles.DashboardPage__title}`}
                                    >
                                      <MapsHomeWorkOutlinedIcon
                                        className={styles.DashboardPage__icon}
                                      />
                                      <h1>HQ</h1>
                                    </div>
                                    {isUserManagerOrAbove && (
                                    <CreateTeamButton
                                      params={params}
                                      isCanCreateTeam={isUserManagerOrAbove}
                                      type="hq"
                                    />
                                    )}
                                  </div>
                                  <div
                                    className={`${styles.DashboardPage__flex}`}
                                  >
                                    <FetchTeamsCards
                                      isCanCreateTeam={isUserManagerOrAbove}
                                      companyId={companyId}
                                      teams={searchResult}
                                      type="hq"
                                    />
                                  </div>
                                </>
                                )}
                                {!isTeamsEmpty && (
                                <>
                                  <div
                                    className={`${styles.DashboardPage__header} ${styles.DashboardPage__relative}`}
                                  >
                                    <div
                                      className={`${styles.DashboardPage__title}`}
                                    >
                                      <GroupsOutlinedIcon className={styles.DashboardPage__icon} />
                                      <h1>Tim</h1>
                                    </div>
                                    {isUserManagerOrAbove && (
                                    <CreateTeamButton
                                      params={params}
                                      isCanCreateTeam={isUserManagerOrAbove}
                                      type="team"
                                    />
                                    )}
                                  </div>
                                  <div
                                    className={`${styles.DashboardPage__flex}`}
                                  >
                                    <FetchTeamsCards
                                      isCanCreateTeam={isUserManagerOrAbove}
                                      companyId={companyId}
                                      teams={searchResult}
                                      type="team"
                                    />
                                  </div>
                                </>
                                )}
                                {!isProjectsEmpty && (
                                <>
                                  <div
                                    className={`${styles.DashboardPage__header} ${styles.DashboardPage__relative}`}
                                  >
                                    <div
                                      className={`${styles.DashboardPage__title}`}
                                    >
                                      <AssignmentOutlinedIcon
                                        className={styles.DashboardPage__icon}
                                      />
                                      <h1>Proyek</h1>
                                    </div>
                                    {isUserManagerOrAbove && (
                                    <CreateTeamButton
                                      params={params}
                                      isCanCreateTeam={isUserManagerOrAbove}
                                      type="project"
                                    />
                                    )}
                                  </div>
                                  <div
                                    className={`${styles.DashboardPage__flex}`}
                                  >
                                    <FetchTeamsCards
                                      isCanCreateTeam={isUserManagerOrAbove}
                                      companyId={companyId}
                                      teams={searchResult}
                                      type="project"
                                    />
                                  </div>
                                </>
                                )}
                              </div>
                              )}
                              {widgetListId === 'widgetMyTaskAndRecentVisit' && (
                              <div
                                className={styles.widgetSection}
                                ref={provided.innerRef}
                                {...provided?.draggableProps}
                                {...provided?.dragHandleProps}
                              >
                                <WidgetSection />
                              </div>
                              )}
                            </>
                          )}
                        </Draggable>
                      ))}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          )}
        </div>
        {!currentCompany?._id && !showWelcomePopUp && (
          <ModalSelectCompany
            open={showSelectCompany}
            userId={user?._id}
            companies={currentCompanies}
            onClose={() => setShowSelectCompany(false)}
          />
        )}
        <ModalWelcomeUser
          open={showWelcomePopUp}
          userId={user?._id}
          onClose={handleCloseWelcomePopUp}
          handleClickDontShowAgain={handleClickDontShowWelcomeAgain}
          isDontShowWelcomeAgain={isDontShowWelcomeAgain}
          showWelcomeProfileForm={showWelcomeProfileForm}
        />
      </PageLoading>
    </>
  );
}

export default DashboardPage;
